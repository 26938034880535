//获取数据展示中的 iframe链接所需的参数
import {
	CompanyGet
} from '@/api/companyGet.js'
import store from '@/store/index.js'

export function companyGet(){
	CompanyGet({}).then(res=>{
		if(res.data.Code==200){
            let {T_key,T_name} = res.data.Data.Data
            store.dispatch('SETPANY', {
                plan: T_key,
                T_name:T_name,
                bl: true
            }) //缓存到vuex
			store.dispatch('SETDATA', {
				...res.data.Data.Data,
				setId: res.data.Data.Data.Id,
			})
		}
	})
}
